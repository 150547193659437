<template>
	<v-container fluid class="donation-list-view mx-4">
		<v-row class="px-4">
			<div
				:style="{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					marginBottom: '10px'
				}"
			>
				<h1>Donations</h1>
			</div>
			<v-spacer></v-spacer>
		</v-row>
		<v-row class="px-4 pt-6 pb-4">
			<el-date-picker
				v-model="date"
				type="daterange"
				start-placeholder="Start date"
				end-placeholder="End date"
				class="date-filter-picker"
				clearable
				@change="dateChange"
			>
			</el-date-picker>
			<v-menu offset-y>
				<template v-slot:activator="{ on, attrs }">
					<Roundup-Button
						color="white"
						v-bind="attrs"
						v-on="on"
						:style="{
							marginLeft: '16px',
							background: activeFilter
								? 'rgba(234, 237, 251) !important'
								: 'inherit'
						}"
						><i class="fa-regular fa-filter"></i>
						{{
							activeFilter ? `Filter: ${activeFilter}` : 'Filter'
						}}
					</Roundup-Button>
				</template>
				<v-list class="">
					<v-list-item
						class="filter-option"
						@click="() => (this.activeFilter = null)"
					>
						<v-list-item-title>Clear Filters</v-list-item-title>
					</v-list-item>
					<v-divider style="width: 100% !important"></v-divider>
					<p class="filter-group-name px-4">
						Status
					</p>

					<v-list-item
						class="filter-option"
						@click="() => (this.activeFilter = 'Success')"
					>
						<v-list-item-title>Success</v-list-item-title>
					</v-list-item>
					<v-list-item
						class="filter-option"
						@click="() => (this.activeFilter = 'Refunded')"
					>
						<v-list-item-title>Refunded</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>

			<v-text-field
				class="ml-4 mt-0 pt-0"
				style="max-width: 400px"
				prepend-inner-icon="fa-regular fa-magnifying-glass"
				placeholder="Search by name or email"
				v-model="tableSearch"
			></v-text-field>
			<Roundup-Button
				color="primary"
				v-if="tableSearch || (date && date.toString()) || activeFilter"
				@click="clearFilters"
				style="margin-left: 16px"
				><i class="fas fa-x mr-2"></i>
				Clear Filters
			</Roundup-Button>

			<v-spacer></v-spacer>
			<Roundup-Button
				color="white"
				@click="handleDownload"
				:disabled="
					$refs.donationsTable &&
						$refs.donationsTable.$children[0].filteredItems
							.length === 0
				"
				>Export
				{{
					$refs.donationsTable &&
					(tableSearch || (date && date.toString()) || activeFilter)
						? $refs.donationsTable.$children[0].filteredItems.length
						: ''
				}}
				Donations</Roundup-Button
			>
		</v-row>
		<v-card class="pb-4 px-4 mx-4 mb-4">
			<v-card-title class="pb-0">
				<h3>Total Donations</h3>
			</v-card-title>
			<v-row class="center pt-0">
				<h1 style="font-size: 4em !important;" v-if="!roi.isLoading">
					{{ currencyFormat(totals.totalDonated) }}
				</h1>
				<v-progress-circular
					class="mb-4"
					v-else
					indeterminate
					size="50"
					color="primary"
				></v-progress-circular>
			</v-row>
			<!-- <div
				class="alert alert-info mx-4"
				style="margin-top: 10px; text-align: center"
			>
				<strong>{{
					currencyFormat(totals.totalDonated - totals.totalFees)
				}}</strong>
				return on investment of
				{{ currencyFormat(totals.totalFees) }} in RoundUp App.
			</div> -->
		</v-card>

		<v-data-table
			:headers="columns"
			:items="items"
			:sort-desc="[false, true]"
			multi-sort
			class="elevation-1 mx-4 table"
			:loading="isLoading"
			:search="tableSearch || (date && date.toString()) || activeFilter"
			:custom-filter="filterSelection"
			loading-text="Fetching your donations..."
			@current-items="items => (this.filteredRows = items)"
			ref="donationsTable"
		>
			<template v-slot:item.created_at="{ item }">
				{{ simpleDateFormat(new Date(item.created_at)) }}
			</template>
			<template v-slot:item.amount="{ item }">
				<v-tooltip bottom>
					<template v-slot:activator="{ on, attrs }">
						<div v-bind="attrs" v-on="on">
							{{ computeAmount(item) }}
						</div>
					</template>
					<span>
						Gross Amount: {{ currencyFormat(item.amount) }} <br />
						Credit Card Fees: {{ currencyFormat(item.stripe_fee) }}
						<br />
						Net Amount:
						{{ currencyFormat(item.amount - item.stripe_fee) }}
					</span>
				</v-tooltip>
			</template>
			<template v-slot:item.donor_name="{ item }">
				<a
					@click="navigateToDonorDetail(item)"
					style="text-decoration: none"
				>
					{{ item.donor_name }}
				</a>
			</template>
			<template v-slot:item.url_host="{ item }">
				{{ item.user.url_host || '' }}
			</template>
		</v-data-table>
	</v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import {
	get,
	numberFormat,
	currencyFormat,
	simpleDateFormat
} from '@/utilities'
import table from '@/mixins/table'
import download from '@/mixins/download'
import moment from 'moment'

import UiButton from '../../ui/Button'
import UiLabel from '../../ui/Label'
import Screen from '../../ui/Screen'
import Chart from '../../ui/Chart'

export default {
	name: 'donations-report-view',
	components: { UiButton, Screen, Chart, UiLabel },
	mixins: [table, download],
	props: ['startDate', 'endDate'],
	computed: {
		...mapState({
			items: ({ reportList }) => reportList.items,
			total: ({ reportList }) => reportList.total,
			graph: ({ reportList }) => reportList.graph,
			isLoading: ({ reportList }) => reportList.isFetching,
			authToken: ({ me }) => me.token,
			roi: ({ reportList }) => reportList.roi
		}),
		totals() {
			let totalDonated = 0
			let totalFees = 0

			this.$refs.donationsTable.$children[0].filteredItems.map(
				({ stripe_error, stripe_fee, application_fee, amount }) => {
					if (!stripe_error) {
						totalDonated += amount
						totalFees += application_fee + stripe_fee
					}
				}
			)

			return { totalDonated, totalFees }
		}
	},
	data() {
		return {
			date: null,
			tableSearch: null,
			activeFilter: null,
			range: [1, 100000],
			filteredRows: [],
			tableParamKeys: ['startDate', 'endDate'],
			columns: [
				{
					text: 'Date',
					value: 'created_at'
				},
				{
					text: 'Donor',
					value: 'donor_name'
				},
				{
					text: 'Email',
					value: 'user.email'
				},
				{ text: 'Amount', value: 'amount' },
				{
					text: 'Status',
					value: 'payment_status'
				},
				{
					text: 'Donation URL',
					value: 'user.url_host'
				}
			]
		}
	},
	head: {
		title: {
			inner: 'Reports - Donations'
		}
	},
	methods: {
		...mapActions(['getDonationsReport', 'getROIReport']),
		navigateToDonorDetail(donation) {
			if (donation.merchant) {
				this.$router.push({
					name: 'donors.merchants.detail',
					params: { donorId: donation.donor_id }
				})
			} else {
				this.$router.push({
					name: 'donors.detail',
					params: { donorId: donation.donor_id }
				})
			}
		},
		filterSelection(value, search, item) {
			if (this.date) {
				const item_date = new Date(item.created_at)
				if (
					!(
						this.date[0].getTime() <= item_date.getTime() &&
						this.date[1].getTime() >= item_date.getTime()
					)
				) {
					return false
				}
			}

			if (this.activeFilter) {
				if (
					!item.payment_status
						.toLowerCase()
						.includes(this.activeFilter.toLowerCase())
				) {
					return false
				}
			}

			if (!this.tableSearch) {
				return true
			}

			return (
				value != null &&
				search != null &&
				typeof value === 'string' &&
				value
					.toString()
					.toLowerCase()
					.indexOf(search.toLowerCase()) !== -1
			)
		},
		clearFilters() {
			this.date = null
			this.activeFilter = null
			this.tableSearch = null
		},
		computeAmount(donation) {
			if (donation.refund_amount) {
				return currencyFormat(donation.refund_amount * -1)
			}
			return currencyFormat(donation.amount)
		},
		handleDownload() {
			window.analytics.track('download_donation_report')
			var id_list = this.$refs.donationsTable.$children[0].filteredItems.map(
				item => item.id
			)

			this.postParameters(
				'/reports/donations/csv',
				{ id_list },
				this.authToken
			)
		},
		dateChange(dates) {
			let params = this.getParameters()
			if (dates) {
				params.startDate = moment(dates[0]).format('YYYY-MM-DD')
				params.endDate = moment(dates[1]).format('YYYY-MM-DD')
			}
			this.setParameters(params)
		},
		numberFormat,
		get,
		currencyFormat,
		simpleDateFormat
	},
	mounted() {
		this.getDonationsReport()

		this.getROIReport()
	}
}
</script>

<style lang="scss" scoped>
@import '../../../styles/variables';
</style>
