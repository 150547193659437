var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"donation-list-view mx-4",attrs:{"fluid":""}},[_c('v-row',{staticClass:"px-4"},[_c('div',{style:({
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				marginBottom: '10px'
			})},[_c('h1',[_vm._v("Donations")])]),_c('v-spacer')],1),_c('v-row',{staticClass:"px-4 pt-6 pb-4"},[_c('el-date-picker',{staticClass:"date-filter-picker",attrs:{"type":"daterange","start-placeholder":"Start date","end-placeholder":"End date","clearable":""},on:{"change":_vm.dateChange},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}}),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
			var on = ref.on;
			var attrs = ref.attrs;
return [_c('Roundup-Button',_vm._g(_vm._b({style:({
						marginLeft: '16px',
						background: _vm.activeFilter
							? 'rgba(234, 237, 251) !important'
							: 'inherit'
					}),attrs:{"color":"white"}},'Roundup-Button',attrs,false),on),[_c('i',{staticClass:"fa-regular fa-filter"}),_vm._v(" "+_vm._s(_vm.activeFilter ? ("Filter: " + _vm.activeFilter) : 'Filter')+" ")])]}}])},[_c('v-list',{},[_c('v-list-item',{staticClass:"filter-option",on:{"click":function () { return (this$1.activeFilter = null); }}},[_c('v-list-item-title',[_vm._v("Clear Filters")])],1),_c('v-divider',{staticStyle:{"width":"100% !important"}}),_c('p',{staticClass:"filter-group-name px-4"},[_vm._v(" Status ")]),_c('v-list-item',{staticClass:"filter-option",on:{"click":function () { return (this$1.activeFilter = 'Success'); }}},[_c('v-list-item-title',[_vm._v("Success")])],1),_c('v-list-item',{staticClass:"filter-option",on:{"click":function () { return (this$1.activeFilter = 'Refunded'); }}},[_c('v-list-item-title',[_vm._v("Refunded")])],1)],1)],1),_c('v-text-field',{staticClass:"ml-4 mt-0 pt-0",staticStyle:{"max-width":"400px"},attrs:{"prepend-inner-icon":"fa-regular fa-magnifying-glass","placeholder":"Search by name or email"},model:{value:(_vm.tableSearch),callback:function ($$v) {_vm.tableSearch=$$v},expression:"tableSearch"}}),(_vm.tableSearch || (_vm.date && _vm.date.toString()) || _vm.activeFilter)?_c('Roundup-Button',{staticStyle:{"margin-left":"16px"},attrs:{"color":"primary"},on:{"click":_vm.clearFilters}},[_c('i',{staticClass:"fas fa-x mr-2"}),_vm._v(" Clear Filters ")]):_vm._e(),_c('v-spacer'),_c('Roundup-Button',{attrs:{"color":"white","disabled":_vm.$refs.donationsTable &&
					_vm.$refs.donationsTable.$children[0].filteredItems
						.length === 0},on:{"click":_vm.handleDownload}},[_vm._v("Export "+_vm._s(_vm.$refs.donationsTable && (_vm.tableSearch || (_vm.date && _vm.date.toString()) || _vm.activeFilter) ? _vm.$refs.donationsTable.$children[0].filteredItems.length : '')+" Donations")])],1),_c('v-card',{staticClass:"pb-4 px-4 mx-4 mb-4"},[_c('v-card-title',{staticClass:"pb-0"},[_c('h3',[_vm._v("Total Donations")])]),_c('v-row',{staticClass:"center pt-0"},[(!_vm.roi.isLoading)?_c('h1',{staticStyle:{"font-size":"4em !important"}},[_vm._v(" "+_vm._s(_vm.currencyFormat(_vm.totals.totalDonated))+" ")]):_c('v-progress-circular',{staticClass:"mb-4",attrs:{"indeterminate":"","size":"50","color":"primary"}})],1)],1),_c('v-data-table',{ref:"donationsTable",staticClass:"elevation-1 mx-4 table",attrs:{"headers":_vm.columns,"items":_vm.items,"sort-desc":[false, true],"multi-sort":"","loading":_vm.isLoading,"search":_vm.tableSearch || (_vm.date && _vm.date.toString()) || _vm.activeFilter,"custom-filter":_vm.filterSelection,"loading-text":"Fetching your donations..."},on:{"current-items":function (items) { return (this$1.filteredRows = items); }},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
						var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.simpleDateFormat(new Date(item.created_at)))+" ")]}},{key:"item.amount",fn:function(ref){
						var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
						var on = ref.on;
						var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.computeAmount(item))+" ")])]}}],null,true)},[_c('span',[_vm._v(" Gross Amount: "+_vm._s(_vm.currencyFormat(item.amount))+" "),_c('br'),_vm._v(" Credit Card Fees: "+_vm._s(_vm.currencyFormat(item.stripe_fee))+" "),_c('br'),_vm._v(" Net Amount: "+_vm._s(_vm.currencyFormat(item.amount - item.stripe_fee))+" ")])])]}},{key:"item.donor_name",fn:function(ref){
						var item = ref.item;
return [_c('a',{staticStyle:{"text-decoration":"none"},on:{"click":function($event){return _vm.navigateToDonorDetail(item)}}},[_vm._v(" "+_vm._s(item.donor_name)+" ")])]}},{key:"item.url_host",fn:function(ref){
						var item = ref.item;
return [_vm._v(" "+_vm._s(item.user.url_host || '')+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }